import { Links, LiveReload, Meta, Outlet, Scripts, useLoaderData, useRouteError } from '@remix-run/react';
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import { Analytics } from '@vercel/analytics/react';
import { type LinksFunction, type MetaFunction } from '@vercel/remix';
import rdtStylesheet from 'remix-development-tools/index.css';

import type { loader } from './_root.server';
import { PageLevelErrorCard } from './src/components/page-level-error-card';
import { TailwindIndicator } from './src/tailwind-indicator';
import tailwindStylesheetUrl from './styles/app.css';

const devTools = process.env.NODE_ENV === 'development' ? [{ rel: 'stylesheet', href: rdtStylesheet }] : [];

export const links: LinksFunction = () => [
...devTools,
{ rel: 'preload', href: '/fonts/Faktum.woff' },
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.eot',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.svg',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.ttf',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.woff',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.woff2',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-200.eot',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-200.svg',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-200.ttf',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-200.woff',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-200.woff2',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.eot',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.svg',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.ttf',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.woff',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.woff2',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.eot',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.svg',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.ttf',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff2',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.eot',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.svg',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.ttf',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.woff',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.woff2',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.eot',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.svg',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.ttf',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.woff',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.woff2',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-800.eot',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-800.svg',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-800.ttf',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-800.woff',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-800.woff2',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.eot',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.svg',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.ttf',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.woff',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.woff2',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.eot',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.svg',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.ttf',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff',
  as: 'font'
},
{
  rel: 'preload',
  href: '/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff2',
  as: 'font'
},
{ rel: 'stylesheet', href: tailwindStylesheetUrl, as: 'style' }];


export const meta: MetaFunction = () => [
{ charset: 'utf-8' },
{ title: 'Embedded Health' },
{ viewport: 'width=device-width,initial-scale=1' }];


export { loader } from './_root.server';

function Document({ children }: {children: React.ReactNode;}) {
  const { primaryColor, primaryForegroundColor, env } = useLoaderData<typeof loader>();

  return (
    <html lang='en'>
      <head>
        <Meta />
        <Links />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.env = ${JSON.stringify(env)}`
          }} />

        <style
          dangerouslySetInnerHTML={{
            __html: `
              :root {
                --primary: ${primaryColor};
                --primary-foreground: ${primaryForegroundColor};
              }
            `
          }} />

      </head>
      <body className='flex antialiased'>
        {children}
        <Scripts />
        <LiveReload />
        <Analytics />
        <TailwindIndicator />
      </body>
    </html>);

}

function App() {
  return (
    <Document>
      <Outlet />
    </Document>);

}

function ErrorBoundary() {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  return (
    <html lang='en'>
      <head>
        <Meta />
        <Links />
      </head>
      <body className='flex antialiased'>
        <PageLevelErrorCard
          title='An unknown error has occurred'
          description='We have been notified & are working to fix this problem.' />

        <Scripts />
        <LiveReload />
        <Analytics />
        <TailwindIndicator />
      </body>
    </html>);

}

let AppExport = withSentry(App, {
  errorBoundaryOptions: {
    fallback: ErrorBoundary
  }
});

if (process.env.NODE_ENV === 'development') {
  const { withDevTools } = require('remix-development-tools');
  AppExport = withDevTools(AppExport);
}

export default AppExport;